<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('IllnessCertificateList') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :disabled="!selected.length"
          @click="sendPrint(null)"
        >
          <v-icon class="me-1">
            {{ icons.mdiPrinter }}
          </v-icon>
          <span class="d-none d-sm-block">{{ $t('print_cert_sick') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          :disabled="!selected.length"
          @click="sendPrint"
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-dialog
            ref="date"
            v-model.trim="isShowDate"
            :return-value.sync="sick_create"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sick_create"
                outlined
                readonly
                dense
                :label="$t('date')"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="sick_create = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="sick_create"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.date.save(sick_create)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="isShowDate = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="sick_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :headers="columns"
        :items="dataTableList"
        hide-default-footer
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        item-key="sick_id"
        show-select
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.sick_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.sick_create`]="{ item }">
          <ConverseDate :date="item.sick_create" />
        </template>
        <template v-slot:[`item.sick_status_id`]="{ item }">
          <StatusBlock :status="item.sick_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="sendPrint(item.sick_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("print") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditCertSick = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="sick_id = item.sick_id;sick_status_id_update = item.sick_status_id;isUpdateStatus = true"
              >
                <v-icon>{{ item.sick_status_id == 1?icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.sick_status_id == 1?$t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <EditCertSick
      v-model="isEditCertSick"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <UpdateStatusCertSick
      :id="sick_id"
      v-model="isUpdateStatus"
      :status-id="sick_status_id_update"
      @onUpdate="fetchDataTable"
    />
    <CertSickOptions
      :id="idList"
      v-model="isPrintCertSick"
    />
  </div>
</template>

<script>
import {
  mdiPrinter, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiCalendarBlank,
} from '@mdi/js'
import useCertSick from './useCertSick'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import EditCertSick from './EditCertSick.vue'
import UpdateStatusCertSick from './UpdateStatusCertSick.vue'
import CertSickOptions from '@/components/prints/CertSickOption.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    StatusBlock,
    ConverseDate,
    EditCertSick,
    UpdateStatusCertSick,
    CertSickOptions,
  },
  setup() {
    return {
      ...useCertSick(),
      icons: {
        mdiPrinter, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiCalendarBlank,
      },
    }
  },
}
</script>
