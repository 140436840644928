<template>
  <v-chip
    :color="status == 1?'success':'error'"
    :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
    small
  >
    <v-icon
      small
      class="me-1"
    >
      {{ status == 1 ?icons.mdiCheck:icons.mdiClose }}
    </v-icon>
    {{ status == 1 ? $t('normal') : $t('suspend') }}
  </v-chip>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'

export default {
  props: { status: String },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
