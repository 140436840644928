<template>
  <v-dialog
    v-model="isPrintCertSick"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('option') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-radio-group
              v-model="selectLang"
              :label="`${$t('')}`"
            >
              <template v-slot:label>
                <span class="font-weight-semibold text-md">
                  {{ $t('choose_language') }}
                </span>
              </template>
              <v-radio
                label="ไทย"
                value="1"
              ></v-radio>
              <v-radio
                label="English"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col
            cols="12"
            md="6"
          >
            <v-radio-group
              v-model="topicSelect"
            >
              <template v-slot:label>
                <span class="font-weight-semibold text-md">
                  {{ $t('topicCertSick') }}
                </span>
              </template>
              <v-radio
                :label="$t('certificate')"
                value="1"
              ></v-radio>
              <v-radio
                :label="$t('checkPaper')"
                value="2"
              ></v-radio>
              <v-radio
                label="Medical Certificate (OPD)"
                value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="print"
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:isPrintCertSick', false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'

export default {
  model: {
    prop: 'isPrintCertSick',
    event: 'update:isPrintCertSick',
  },
  props: {
    isPrintCertSick: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const selectLang = ref('1')
    const topicSelect = ref('1')
    const shopId = ref(localStorage.getItem('shop_id'))
    watch(() => props.isPrintCertSick, value => {
      if (value) {
        selectLang.value = '1'
      }
    })

    const print = () => {
      let name = selectLang.value == 1 ? 'printCertSickNewTh' : 'printCertSickNew'

      if (topicSelect.value == '3') {
        const routeData = router.resolve({
          name,
          query: {
            id: String(props.id),
            type: props.type,
            topic: topicSelect.value,
          },
        })
        window.open(routeData.href, '_blank')
      } else {
        if (selectLang.value == '2') {
          name = 'printCertSick_en'
        } else {
          name = 'printCertSick'
        }
        const routeData = router.resolve({
          name,
          query: {
            id: String(props.id),
            type: props.type,
            topic: topicSelect.value,
          },
        })
        window.open(routeData.href, '_blank')
      }
    }

    return {
      selectLang,
      topicSelect,
      shopId,
      print,
    }
  },
}
</script>
