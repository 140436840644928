import basicStatus from '@/@fake-db/data/basicStatus.json'
import certSick from '@/api/drugAndCert/certSick'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

export default function useCertSick() {
  const moment = require('moment')

  const statusList = ref(basicStatus.data)
  const sick_status_id = ref(1)
  const searchtext = ref('')
  const isShowDate = ref(false)
  const sick_create = ref(moment(new Date()).format('YYYY-MM-DD'))

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'sick_id',

      width: 30,
    },
    {
      text: i18n.t('a_number'),
      value: 'sick_code',

      width: 100,
    },
    {
      text: i18n.t('applicant'),
      value: 'customer_fullname',

      width: 200,
    },
    {
      text: `${i18n.t('id_card')}`,
      value: 'customer_idcard',

      width: 180,
    },
    {
      text: i18n.t('request_date'),
      value: 'sick_create',

      width: 140,
    },
    {
      text: i18n.t('doctor'),
      value: 'user_fullname',

      width: 200,
    },
    {
      text: i18n.t('status'),
      value: 'sick_status_id',

      width: 100,
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 150,
    },
  ])
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const segmentId = ref(0)
  const loading = ref(false)

  const selected = ref([])

  const isEditCertSick = ref(false)
  const dataEdit = ref({})

  const isUpdateStatus = ref(false)
  const sick_id = ref('')
  const sick_status_id_update = ref('')

  const isPrintCertSick = ref(false)
  const idList = ref([])

  const { certSickList } = certSick

  const fetchDataTable = event => {
    loading.value = true
    certSickList({
      searchtext: searchtext.value,
      sick_status_id: sick_status_id.value,
      sick_create: sick_create.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page > +count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, sick_status_id, sick_create, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  const sendPrint = id => {
    if (id) {
      idList.value = [id]
    } else {
      idList.value = selected.value.map(item => item.sick_id)
    }
    isPrintCertSick.value = true
  }

  return {
    isUpdateStatus,
    sick_id,
    sick_status_id_update,
    isEditCertSick,
    dataEdit,
    selected,
    statusList,
    sick_status_id,
    searchtext,
    isShowDate,
    sick_create,
    dataTableList,
    totalDataTableList,
    columns,
    options,
    footer,
    totalPage,
    segmentId,
    loading,
    fetchDataTable,
    isPrintCertSick,
    idList,
    sendPrint,
  }
}
