import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const certSickList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'sick/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get cert sick list err : ', err)

      return {}
    })

  return response
}
const certSickGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'sick',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get cert sick  err : ', err)

      return {}
    })

  return response
}
const certSickUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'sick',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Update cert sick err : ', err)

      return {}
    })

  return response
}
const certSickStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'sick/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status cert sick  err : ', err)

      return {}
    })

  return response
}
export default {
  certSickList, certSickUpdate, certSickStatusUpdate, certSickGet,
}
