<template>
  <div>
    <v-dialog
      v-model="isEditCertSick"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('editCertSick') }}
        </v-card-title>
        <v-form
          ref="formEditCertSick"
          @submit.prevent="updateCertSick"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="dataEditLocal.sick_code"
                  outlined
                  hide-details
                  :label="$t('a_number')"
                  :rules="[required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-dialog
                  ref="dateStart"
                  v-model.trim="modal"
                  :return-value.sync="dataEditLocal.sick_start"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="dataEditLocal.sick_start"
                      :label="$t('should_be_rested_since')"
                      outlined
                      :rules="dataEditLocal.disallow_sick=='1'?[]:[required]"
                      dense
                      hide-details
                      readonly
                      :disabled="dataEditLocal.disallow_sick == 1"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="dataEditLocal.sick_start"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateStart.save(dataEditLocal.sick_start)"
                    >
                      {{ $t("confirm") }}
                    </v-btn>
                    <v-btn
                      text
                      color="secondary"
                      @click="modal = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-dialog
                  ref="dateEnd"
                  v-model.trim="modal1"
                  :return-value.sync="dataEditLocal.sick_end"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="dataEditLocal.sick_end"
                      :label="$t('to')"
                      readonly
                      outlined
                      hide-details
                      :rules="dataEditLocal.disallow_sick=='1'?[]:[required]"
                      dense
                      :disabled="dataEditLocal.disallow_sick == 1"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="dataEditLocal.sick_end"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateEnd.save(dataEditLocal.sick_end)"
                    >
                      {{ $t("confirm") }}
                    </v-btn>
                    <v-btn
                      text
                      color="secondary"
                      @click="modal1 = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="dataEditLocal.disallow_sick"
                  :true-value="'1'"
                  class="mt-0 pt-0"
                  hide-details
                  :false-value="'0'"
                  :label="$t('inappropriate')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="dataEditLocal.sick_symptom"
                  outlined
                  dense
                  hide-details
                  :label="$t('symptoms')"
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="dataEditLocal.sick_diagnose"
                  :label="$t('diagnose')"
                  outlined
                  hide-details
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-radio-group
                  v-model="dataEditLocal.sick_air_id"
                  class="py-0 my-0"
                >
                  <v-radio
                    :label="$t('unknown_board')"
                    value="0"
                  ></v-radio>
                  <v-radio
                    :label="$t('can_board')"
                    value="1"
                  ></v-radio>
                  <v-radio
                    :label="$t('unable_board')"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-cert-sick',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import certSick from '@/api/drugAndCert/certSick'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditCertSick',
    event: 'update:is-edit-cert-sick',
  },
  props: {
    isEditCertSick: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditCertSick = ref(null)
    const dataEditLocal = ref({})
    const loading = ref(false)
    const modal = ref(false)
    const modal1 = ref(false)

    const { certSickUpdate } = certSick
    const updateCertSick = () => {
      const isFormValid = formEditCertSick.value.validate()
      if (!isFormValid) return
      loading.value = true
      certSickUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-cert-sick', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, value => {
      if (value) {
        value.disallow_sick = value.sick_start ? '0' : '1'
        dataEditLocal.value = JSON.parse(JSON.stringify(value))
      }
    })

    return {
      loading,
      modal,
      modal1,
      formEditCertSick,
      dataEditLocal,
      updateCertSick,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
